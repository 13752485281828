/* Register CSS */

.register-card {
    p>a {

        color: $primary;
    }
}

.register-form {
    position: relative;
    z-index: 1;

    .form-group {
        position: relative;
        z-index: 1;

        .form-control {
            border: 0;
            border: 2px solid transparent;
            font-size: 14px;

            &:focus {
                border-color: $primary;
            }
        }
    }
}

.login-meta-data {
    .forgot-password {
        color: $primary;
        font-size: 16px;
    }

    .custom-checkbox .custom-control-label::before {
        border-radius: 50%;
    }
}

.register-area {
    z-index: 1;
    padding-bottom: 120px;
}

#comingSoonClock{
    display: flex;
    align-items: center;
    justify-content: center;
    
    span {
        font-size: 1rem;
        color: $heading;
        font-weight: 700;

        @media #{$breakpoint-lg} {
            font-size: 1.5rem;
        }
    }
}

.active-tab{
    // background-color: $text-orange;
    // border-radius: 25px;
}

.active-tab-text{
    color:  $text-orange;
}

.login-btn{
    background-color: $text-green;
    padding: 5px;
    border-radius: 25px;
}

.login-text{
    color: $white;
}