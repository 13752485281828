/* Error CSS */

.saasbox-error-area {
    text-align: center;

    h5 {
        font-size: 1.5rem;

        @media #{$breakpoint-lg} {
            font-size: 2rem;
        }
    }
}

.home-navigate-btn{
    background-color: $text-orange;
    color: $white;
    &:hover{
        background-color: $text-orange;
        color: $white;
    }
}