/* Demo CSS */

.demo-hero-area {
    position: relative;
    z-index: 2;
}

.demo-hero {
    .version-number {
        padding: 0.25rem 0.75rem;
        color: $heading;
        background-color: $white;
        display: inline-block;
        border-radius: 30px;
        font-size: 14px;
    }

    .animated-heading {
        span {
            font-size: 3.5rem;
            padding: 0;
            color: $white;
            font-weight: 700;

            b {
                font-weight: 700;
                margin-left: 0.5rem;
                color: $warning;
            }
        }
    }
}

.single-preview-area {
    h5 {
        transition-duration: 400ms;
    }

    a {
        display: block;

        img {
            transition-duration: 500ms;
            border: 1px solid $border;
            border-radius: 0.5rem;
        }
    }

    &:hover,
    &:focus {
        a img {
            transform: translateY(-5px);
        }

        h5 {
            color: $primary;
        }
    }
}

.demo-single-feature {
    background-color: $white;
    transition-duration: 500ms;

    img {
        max-height: 40px;
    }

    &:hover,
    &:focus {
        box-shadow: 0 10px 71px rgba(47, 91, 234, 0.175);
        transform: scale(1.1);
        z-index: 100;
    }
}

.single-more-feature {

    i {
        display: inline-block;
        width: 25px;
        font-size: 16px;
    }
}