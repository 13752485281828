/* Shop CSS */

.shop-card {
    position: relative;
    border-radius: 25px;
    z-index: 1;
    transition-duration: 500ms;
    box-shadow: 0 1rem 3rem rgba(47,91,234,.175)!important;

    .product-img-wrap {
        position: relative;
        z-index: 1;
        overflow: hidden;
        border-radius: 6px 6px 0 0;

        img {
            transition-duration: 500ms;
        }

        .love-product {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            background-color: $white;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            top: 30px;
            left: 30px;
            z-index: 10;
            font-size: 1rem;
            color: $danger;

            i {
                line-height: 1;
            }

            &.active,
            &:hover,
            &:focus {
                background-color: $danger;
                color: $white;
            }
        }
    }

    .product-name {
        color: white;
        a {
            font-size: 1.125rem;
            font-weight: 700;
            display: block;
            margin-bottom: 0.375rem;
        }
    }

    .price {
        span {
            text-decoration: line-through;
            color: $text;
        }
    }

    .product-add-to-cart {
        button {
            position: relative;
            z-index: 1;
            display: block;
            background-color: $warning;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            text-align: center;
            line-height: 40px;
            font-size: 18px;
            color: $heading;

            &::after {
                transition-duration: 500ms;
                width: 130%;
                height: 130%;
                position: absolute !important;
                left: 50%;
                top: 50%;
                z-index: -50;
                content: "";
                background-color: $warning;
                opacity: 0.3;
                border-radius: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    &:hover,
    &:focus {
        transform: translateY(-10px);
        box-shadow: 0 0.25rem 0.25rem 0 rgba(15, 30, 150, 0.2);

        .product-img-wrap img {
            transform: scale3d(1.15, 1.15, 1);
        }

        .product-add-to-cart {
            button {
                background-color: $primary;
                color: $white;

                &::after {
                    animation: cartbtn linear 1000ms infinite;
                    background-color: $primary;
                }
            }
        }
    }
}

@keyframes cartbtn {
    0% {
        width: 100%;
        height: 100%;
        opacity: 1;
    }

    70% {
        width: 150%;
        height: 150%;
        opacity: 0;
    }

    100% {
        width: 100%;
        height: 100%;
        opacity: 0;
    }
}

.shop-meta {
    select {
        max-width: 150px;
    }
}

.product-meta{
    border-radius: 0px 0px  25px 25px;
    background-color: $heading;
}

.product-meta-title {
    background-color: $warning;
    width: 100%;
    border-radius: 6px 6px 0 0;
}

.product-description-card {
    .product-price {
        color: $primary;

        span {
            margin-left: 1rem;
            color: $text;
            text-decoration: line-through;
        }
    }

    input {
        width: 100px;
        text-align: center;
    }
}

.product-review-card {
    .ratings-list {
        li {
            color: $text;
            font-size: 20px;
            margin: 0 2px;

            &.active {
                color: $warning;
            }
        }
    }
}

.cart-table {
    text-align: center;

    .table td,
    .table th {
        vertical-align: middle;
        border-color: $border;
        color: $text;
        font-size: 14px;
    }

    img {
        max-width: 40px;

        @media #{$breakpoint-lg} {
            max-width: 70px;
        }
    }

    .qty-text {
        border: 2px solid $border;
        max-width: 50px;
        text-align: center;
        height: 44px;
        border-radius: 6px;
        transition-duration: 300ms;
        font-weight: 600;

        &:focus {
            border-color: $primary;
        }
    }
}

.order-form {
    border-radius: .5rem;
    padding: 2rem;

    @media #{$breakpoint-sm} {
        padding: 3rem;
    }

    input[type=radio]+label {
        color: $text;
    }

    input[type=radio]:checked+label {
        color: $primary;
    }

    .payment-meta {
        padding-left: 28px;

        p {
            font-size: 14px;
        }
    }
}

.custom-checkbox .custom-control-label::before {
    border-radius: 5.25rem;
}

.shop-fullwidth{
    background-color: #f1f4fd !important;
    padding-top: 70px;
    padding-bottom: 70px;
}