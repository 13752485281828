/* Video CSS */

.video-area {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 400px;
}

.video-play-btn {
    position: absolute;
    z-index: 1;
    box-shadow: 0 6px 50px 8px rgba(21, 131, 233, 0.15);
    width: 100px;
    height: 100px;
    display: inline-block;
    background-color: $warning;
    border-radius: 50%;
    font-size: 3rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    i {
        padding-left: 5px;
        line-height: 100px;
    }

    .video-sonar {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        top: 0;
        left: 0;
        z-index: -10;
        background-color: $warning;
        animation: video-sonar 2s linear infinite;

        &::after {
            position: absolute;
            width: 130px;
            height: 130px;
            border-radius: 50%;
            background-color: $warning;
            content: '';
            top: -15px;
            left: -15px;
            z-index: -50;
        }

        &::before {
            position: absolute;
            width: 160px;
            height: 160px;
            border-radius: 50%;
            background-color: rgba(255, 193, 7, .7);
            content: '';
            top: -30px;
            left: -30px;
            z-index: -100;
        }
    }
}

@keyframes video-sonar {
    0% {
        opacity: 1;
        transform: scale(0.5);
    }

    100% {
        opacity: 0;
        transform: scale(1.25);
    }
}